export default {
  basicViewItem: [{
    prop: 'id',
    label: '',
    Object: 'value'
  }, {
    prop: 'name',
    label: '',
    Object: 'value'
  }, {
    prop: 'name_en',
    label: '',
    Object: 'value'
  }, {
    prop: 'ranking',
    label: '',
    Object: 'value'
  }, {
    prop: 'code2',
    label: '',
    Object: 'value'
  }, {
    prop: 'phone_number_code',
    label: '',
    Object: 'value'
  }, {
    prop: 'updated_at',
    label: '',
    Object: 'value'
  }, {
    prop: 'flag_icon_url',
    label: '',
    Object: 'value',
    image: true
  }]
}
